<template>
  <div>
    <BaseMarquee
      :slides-count="slidesCount"
      :animation-time="blok.animation_time"
      :margin-right="blok.margin_right"
      :is-narrow="blok.is_narrow"
      :gradient-color="blok.gradient_color.value"
      :is-desktop-only="blok.is_desktop_only"
      :slide-width="blok.slide_width"
    >
      <template #default>
        <StoryblokComponent
          v-for="childBlok in doubledItems"
          :key="childBlok._uid"
          :blok="childBlok"
        />
      </template>
    </BaseMarquee>
    <div
      v-if="blok.is_desktop_only && blok.mobile_content"
      class="marquee__static-items"
    >
      <StoryblokComponent
        v-for="childBlok in blok.mobile_content"
        :key="childBlok._uid"
        :blok="childBlok"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { SbMarqueeGeneral } from '@/types'
interface Props {
  blok: SbMarqueeGeneral
}
const props = defineProps<Props>()

const doubledItems = computed(() => {
  return [...props.blok.items, ...props.blok.items]
})

const slidesCount = computed(() => {
  return props.blok.items.length
})
</script>

<style scoped lang="scss">
.marquee__static-items {
  @media (min-width: $breakpoint-lg) {
    display: none;
  }
}
</style>
